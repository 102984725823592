import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Up Start Finance</title>
        <meta property="og:title" content="Up Start Finance" />
      </Helmet>
      <div className="home-navbar navbar-container">
        <div className="max-width">
          <div className="home-logo">
            <span className="home-text">
              <span className="home-text01 brandName">Anastasiia</span>
              <span className="home-text02 brandName">-</span>
              <span className="home-text03 brandName">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text04">pro</span>
            </span>
          </div>
          <div className="home-links">
            <div>
              <Script
                html={`<a href="maito:anastasiiaa.pro@gmail.com" style="color:white;">anastasiiaa.pro@gmail.com</a>`}
              ></Script>
            </div>
          </div>
        </div>
      </div>
      <div className="home-hero hero-container section-container">
        <div className="home-container01 max-width">
          <div className="home-container02">
            <img alt="image" src="/1qqq-400h.jpeg" className="home-image" />
          </div>
          <div className="home-container03">
            <h1 className="home-text05">
              <span className="home-text06">Hey</span>
              <span className="home-text07">.</span>
              <br className="home-text08"></br>
              <span className="home-text09">I&apos;m the</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text11">UX</span>
              <br className="home-text12"></br>
              <span className="home-text13"> Designer</span>
              <br></br>
              <span className="home-text15">You are</span>
              <span className="home-text16"> </span>
              <br className="home-text17"></br>
              <span className="home-text18">looking</span>
              <span className="home-text19">
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text20">for</span>
              <span className="home-text21">.</span>
              <br></br>
            </h1>
          </div>
        </div>
      </div>
      <div className="home-section section-container">
        <div className="home-max-width1 max-width">
          <div className="home-container04">
            <img
              alt="image"
              src="/app11-600w.png"
              className="home-hero-image"
            />
          </div>
          <div className="home-content">
            <span className="home-text23 beforeHeading">get started</span>
            <h1 className="home-text24">My UX skills</h1>
            <div className="home-step">
              <div className="home-number">
                <span className="home-text25">1</span>
              </div>
              <div className="home-container05">
                <span className="home-title">User Research</span>
                <span className="home-text26">
                  Uncovering insights through empathetic observation and
                  qualitative/quantitative methods to inform design decisions.
                </span>
              </div>
            </div>
            <div className="home-step1">
              <div className="home-number1">
                <span className="home-text27">2</span>
              </div>
              <div className="home-container06">
                <span className="home-title1">Information Architecture</span>
                <span className="home-text28">
                  <span>
                    Organizing complex information into intuitive and structured
                    systems for seamless user navigation.
                  </span>
                  <br className="home-text30"></br>
                  <br></br>
                </span>
              </div>
            </div>
            <div className="home-step2">
              <div className="home-number2">
                <span className="home-text32">3</span>
              </div>
              <div className="home-container07">
                <span className="home-title2">
                  Wireframing &amp; Prototyping
                </span>
                <span className="home-text33">
                  Translating ideas into tangible and interactive
                  representations to visualize user flows and interactions.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section1 section-container">
        <div className="home-container08 max-width">
          <img
            alt="image"
            src="/light-table-1200w.png"
            className="home-image1"
          />
          <div className="home-container09">
            <div className="home-step3">
              <div className="home-number3">
                <span className="home-text34">4</span>
              </div>
              <div className="home-container10">
                <span className="home-title3">Interaction Design</span>
                <span className="home-text35">
                  Creating intuitive and engaging interactions that guide users
                  through digital experiences.
                </span>
              </div>
            </div>
            <div className="home-step4">
              <div className="home-number4">
                <span className="home-text36">5</span>
              </div>
              <div className="home-container11">
                <span className="home-title4">Data Analysis</span>
                <span className="home-text37">
                  Leveraging analytical tools to optimize user experiences and
                  inform design decisions.
                </span>
              </div>
            </div>
            <div className="home-step5">
              <div className="home-number5">
                <span className="home-text38">6</span>
              </div>
              <div className="home-container12">
                <span className="home-title5">User Persona Creation</span>
                <span className="home-text39">
                  Crafting representative profiles to guide design decisions
                  based on user needs and behaviors.
                </span>
              </div>
            </div>
            <div className="home-step6">
              <div className="home-number6">
                <span className="home-text40">7</span>
              </div>
              <div className="home-container13">
                <span className="home-title6">Front-End Development</span>
                <span className="home-text41">
                  Proficient in HTML, CSS, and Bootstrap for creating responsive
                  and visually appealing user interfaces.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-hero1 hero-container section-container">
        <div className="home-max-width2 max-width">
          <div className="home-content1">
            <h1 className="home-title7">
              Balancing empathy and analysis for seamless user journeys
            </h1>
            <span className="home-description">
              My secret weapon? Empathy. I dive deep into user desires,
              motivations, and pain points. This understanding forms the
              foundation for designs that resonate on a profound level,
              captivate, engage, and inspire.
            </span>
            <div className="home-container14">
              <button
                type="button"
                className="home-button button button-gradient"
              >
                Contact
              </button>
            </div>
          </div>
          <div className="home-image2">
            <img
              alt="image"
              src="/app2-600w.png"
              className="home-hero-image1 profile"
            />
          </div>
        </div>
      </div>
      <footer className="home-footer">
        <div className="home-links-container">
          <div className="home-container15">
            <div className="underline highlight home-div1">
              <Script
                html={`<a href="maito:anastasiiaa.pro@gmail.com" style="color:white;">anastasiiaa.pro@gmail.com</a>`}
              ></Script>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
